input[type=radio] {
   display: none;
}

.bloque-tabs {
   width: 300px;

   &__menu {

      #tabs-1,
      #tabs-2,
      #tabs-3 {
         display: none;
      }
   }

   &__body {
      min-height: 80px;
      margin-top: .5rem;
   }
}


.label-menu {
   cursor: pointer;
   margin: 0;
   border-radius: 4px;
   background-color: $border-color;
   padding: .2rem .3rem;
   font-size: .65rem;
   min-width: 3rem;
   text-align: center;

   &:hover,
   &:active {
      background-color: $navy;
      color: white;
   }
}

div[class*="tabs-bloque__"] {
   display: none;
}

ul {
   margin-bottom: 0 !important;
}

.tabs-bloque__sitio {
   display: block;
}

//al hacer click en alertas
#tabs-1[type="radio"]:checked~.bloque-tabs__body .tabs-bloque__sitio {
   display: block;
}

//al hacer click en sitio
#tabs-2[type="radio"]:checked~.bloque-tabs__body .tabs-bloque__e1 {
   display: block;
}

//al hacer click en sitio
#tabs-3[type="radio"]:checked~.bloque-tabs__body .tabs-bloque__e2 {
   display: block;
}

/* 
.datos-reales {
   border-left: 2px solid $border-color;
   padding-left: 0.5rem;

   label {
      cursor: pointer;
      margin: 0;

   }

   #perfil-1,
   #perfil-2 {
      display: none;
   }

   .escort-2 {
      display: none;
   }

   .perfil-1 {
      margin-right: 0.5rem;
   }


   .escort-1,
   .escort-2 {
      min-width: 17rem;

      ul {
         margin: 0;

         li:first-child {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 17rem;
         }
      }
   }

   #perfil-1[type="radio"]:checked~.escort-1 {
      display: block;
   }

   #perfil-1[type="radio"]:checked~.escort-2 {
      display: none;
   }

   #perfil-1[type="radio"]:checked~.perfil-1,
   #perfil-2[type="radio"]:checked~.perfil-2 {
      span {
         font-weight: bold;
         color: $navy;
      }
   }

   #perfil-2[type="radio"]:checked~.escort-2 {
      display: block;
   }

   #perfil-2[type="radio"]:checked~.escort-1 {
      display: none;
   }
} */