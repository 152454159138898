// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.dropdown-menu {
  background-color: $ibox-content-bg;
  border: 1px solid $border-color !important;
  li {
    a {
      color: $text-color !important;
      &:hover {
        color: $navy !important;
      }
    }
  }
}

.barra-button {
  background-color: $light-gray;
  border: 1px solid $border-color !important;
  li {
    a {
      color: $text-color !important;
      &:hover {
        color: $ibox-content-bg !important;
      }
    }
  }
}



.activa {
  color: var(--green);
}
.suspendida {
  color: var(--purple);
}

.desactiva {
  color: var(--yellow);
}

.deuda {
  color: var(--red);
}

.terminada {
  color: lightgray;
}

.btn-subir,
.btn-link,
.btn-galerias,
.btn-retoque,
.btn-retoque,
.btn-propiedad,
.btn-descarga,
.btn-add,
.btn-admin {
  @include botonesperfil(var(--cyan));
}


.btn-admin {
  color: $gray;
  svg {
    fill: $gray;
    margin-right: 0.3rem;
  }
  &.btn-retoque,
  &.btn-portada {
    display: flex;
    align-items: center;
  }
  &:hover,
  &:active,
  &:focus {
    svg {
      fill: white;
    }
  }
}


.boton-mostrar-ocultar {
  .fa-eye-slash,
  .fa-eye {
    color: $gray;
  }
}
