// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

body {
  background-color: $gray;
}

.container {
  max-width: 1820px;
}

.navegacion-contenido {
  margin-bottom: 1rem;
  width: 100%;
}

.nav-tabs {
  justify-content: space-between;
}


.dropdown-galeria {
  background-color: $light-gray !important;
  border: 1px solid $border-color !important;

  a {
    &:hover {
      color: $ibox-content-bg !important;
    }
  }
}


/* ul {
  list-style: none;
  padding: 0;

  li {
    margin: 0;

    a {
      color: $text-color !important;

      &:hover {
        color: $navy !important;
      }
    }

    b {
      color: white;
    }
  }
} */

.dl-datos {
  margin: 1rem 0 0 0;

  dt {
    float: left;
    font-weight: normal;
    margin-right: 1rem;
    width: 130px;
  }

  dd {
    font-weight: bold;
    color: $text-color;
    display: flex;
    flex-flow: row wrap;
  }
}

.nav-tabs {
  border-bottom: 1px solid $border-color;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $text-color;
  background-color: $border-color;
  border-color: $border-color;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;

  &-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: $ibox-title-bg;
    border-color: $border-color;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 90px 8px 15px;
    min-height: 48px;
    position: relative;
    clear: both;

    @include border-top-radius(4px);

    .ibox-tools {
      float: none;
      margin-top: 0;
      position: absolute;
      top: 11px;
      right: 15px;
      padding: 0;
      display: flex;
      align-items: center;

      &.tool-gallery {
        top: 20px;

        a {
          margin-left: 10px;
        }
      }
    }

    b {
      color: white;
    }
    &.small {
      padding: 15px 15px 8px 15px;
    }
  }

  &-content {
    background-color: $ibox-content-bg;
    color: inherit;
    padding: 15px 20px 20px 20px;

    border-color: $border-color;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
    @include border-bottom-radius(4px);
  }

  &-footer {
    background-color: $ibox-content-bg;
    color: inherit;
    border-top: 1px solid $border-color;
    font-size: 90%;
    padding: 10px 15px;
    @include border-bottom-radius(4px);

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    a {
      float: right;
    }
  }

  &::before,
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  &.collapsed {
    .ibox-content {
      display: none;
    }

    .fa.fa-chevron-up:before {
      content: "\f078";
    }

    .fa.fa-chevron-down:before {
      content: "\f077";
    }
  }
}

a {
  color: $text-color;

  &:hover {
    color: white;
  }
}

.text-blur {
  color: transparent;
  text-shadow: 0 0 5px white;

  &:hover {
    color: white;
    text-shadow: 0 0 0 white;
    cursor: pointer;
  }
}

/* .text-blur {
  background-color: white;
  &:hover {
    background-color: transparent;
    cursor: pointer;
  }
} */




//CSS PARA EL PERFIL
//barra superior del perfil
.top-content {
  @include border-top-radius(5px);
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  .bloque-alertas {
    text-align: center;
    width: 100px;

    .fa-exclamation-triangle {
      font-size: 2.5rem;
      color: var(--yellow);
      margin-bottom: .5rem;
    }

    p {
      margin: 0;
    }
  }

  //imagen con la portada
  .bloque-portada {
    display: flex;
    flex-flow: row nowrap;

    img {
      height: 6rem;
      width: 6rem;
      border: 4px solid $navy;
    }
  }

  //nombre, tipo
  .bloque-datos {

    .datos-publicacion {
      margin-right: 1.5rem;

      h2 {
        margin: 0 0 0.3rem;
        color: white;

        span:first-child {
          position: relative;

          .fa-star {
            position: absolute;
            top: 11px;
            left: -22px;
            font-size: 0.9rem;
          }
        }
      }

      h3 {
        margin: 0 0 0.3rem;
        font-weight: normal;

        a {
          color: $text-color;

          &:hover {
            color: $navy;
          }
        }
      }

      p {
        margin: 0;

        b {
          color: white;
        }
      }
    }

  }



  .bloque-sitio {
    ul {
      list-style: none;
      padding: 0;
      border-left: 2px solid $border-color;
      margin-bottom: 0;

      li {
        margin-left: 0.5rem;
      }
    }
  }

  //span 4
  .bloque-botones {
    margin-left: auto;

    button {
      margin-right: 0.5rem;
    }
  }

}


//RESUMEN


.resumen-document {
  display: flex;
}

//cuando que tiene los documentos en el admin, rut, acuerdo etc
.tag-document {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 4px;
  border: 1px solid $border-color;
  width: 8rem;
  padding: 0.3rem;
  max-width: 18rem;
  align-items: center;

  &__input {
    height: 2rem;
    padding-top: 0.3rem;
    margin: 0 0.3rem;

    label {
      margin: 0;
    }
  }

  &__document {
    a {
      color: white;

      &:hover {
        color: $navy;
      }
    }
  }

  &:hover {
    background-color: $border-color;
  }
}

.tabla-historial {
  td {
    b {
      color: white;

      &.activa {
        @extend .desactiva;
      }

      &.suspendida {
        @extend .desactiva;
      }

      &.deuda {
        @extend .desactiva;
      }
    }
  }
}

.obs-content {
  background: $gray;
  border-radius: 8px;
  padding: 15px;

  &__top {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 1rem;

    span:first-child {
      margin-right: 0.5rem;
    }

    span:last-child {
      margin-left: auto;
    }
  }

  &__body {
    .obs-file {
      margin-right: 0.5rem;
      display: inline-block;

      i {
        font-size: 1.5rem;
      }
    }
  }
}

.modal-content {
  .modal-header {
    flex-direction: column;
    align-items: center;

    &_top {
      width: 100%;
      display: flex;
      align-items: flex-start;

      .modal-title {
        color: $gray;
      }
    }

    &_menu {
      margin-top: 1rem;

      &-conteo {
        display: flex;
        justify-content: center;
        height: 1.5rem;
        width: 100%;
        margin-bottom: .5rem;

        button {
          margin: 0 .3rem;
        }

        span {
          width: 4rem;
          display: flex;
          justify-content: center;
          font-size: .8rem;
          color: $gray;

          p {
            margin: 0 .3rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .modal-body {
/*     p {
      color: $gray;
    } */

    &_foto {
      min-height: 20rem;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
}
