// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin sinList(){
  padding: 0;
  list-style: none;
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}


//mixin para los botones del sitio
@mixin botonesperfil($color) {
  border-color: lightgray;
  background: lightgray;
  &:hover,
  &:focus,
  &.active {
    background: darken($color: #17a2b8, $amount: 10%);
    border-color: darken($color: #17a2b8, $amount: 10%);
    color: white;
  }
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}


@mixin light-shadow() {
  box-shadow: 0 0 3rem 0 lighten($gray, 2%);
  border-top: 1px solid lighten($gray, 4%);
  border-bottom: 1px solid lighten($gray, 4%);
  position: relative;
  z-index: 3;
}


@mixin iconcenter ($color, $font-size){
  color: $color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size;
}

@mixin imgError {
  background: darken(#09152b, 2%) url("/img/logo2line.svg") no-repeat 50% center;
  box-shadow: inset 0 0 0px 1px lighten(#09152b, 10%);
  background-size: 60%;
}

@mixin imgTransfer {
  background: #cecece url("/img/micuenta/female.jpg") no-repeat 50% center;
  background-size: 90%;
}
//cambiar la ruta quitando mi cuenta
@mixin imgTransfer2 {
  background: #cecece url("/img/micuenta/female.jpg") no-repeat 50% center;
  background-size: 90%;
}
