$sizeDeuda: 2em;
$suspender: var(--purple);
$activa: var(--green);
$colorBorder: #e7eaec;
$pink: pink;
$azultxt: #3e5476;
$greenT: #1ab394;
$pendiente: #9c9c08;

.label-pe {
  color: white !important;
  background-color: #6e7070 !important;
  padding: 2px 4px !important;
  font-size: 11px !important;
}

.info-num {
  padding-left: 15px;
  list-style: decimal;
  li {
    margin-bottom: 15px;
  }
}
.info-reg {
  @extend .info-num;
  list-style: disc;
}

.bold {
  font-weight: bold;
}

.red {
  color: red;
}

.aLink {
  color: $blue;
  &:hover {
    color: lighten($blue, 5%);
  }
}
.fondo-deuda.bloque-deuda {
 background-color: #3e5476;
}
.bloque-deuda {
  text-align: center;
  padding: 1rem;
  color: white;
  border-radius: 5px;
  margin-top: 1rem;
}

.icon-alert {
  font-size: 2rem;
  margin: 0.5rem 0.5rem 0 0;
}

.deuda-cifra {
  p {
    color: white;
  }
}

.size-deuda {
  font-size: $sizeDeuda;
}
//clase para los días que le quedan (versión independiente)
.size-fecha {
  font-size: 1.5rem;
  font-weight: bold;
}

.bloque-suspension {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-width: 90px;

  .toggle {
    margin: 0 auto;
  }
  button {
    width: 90px;
    height: 34px;
    font-size: 13px;
  }
  button:first-child {
    margin-bottom: 5px;
  }
  i.fa-lock {
    font-size: 40px;
  }
}

.info-suspension {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  &-dl {
    width: 125px;
  }
}

#back2Top {
  position: fixed;
  bottom: 20px;
  outline: none;
  right: 30px;
  z-index: 99;
  border: none;
  background-color: $greenT;
  color: #fff;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
}
#back2Top:hover {
  color: white;
  background-color: #189c82;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.sinPortada {
  @include imgTransfer;
  width: 125px;
  height: 185px;
}

.sinPortadaSeccion {
  @include imgTransfer2;
}

a.btn.btn-xs.btn-default {
  text-indent: 0;
}

.link-micuenta {
  color: #0056b3 !important;
  display: block;
  margin-top: 0.5rem;
  &:hover {
    color: darken(#0056b3, 5%);
  }
}

.menu-svg {
  &:hover {
    .icon-disp {
      fill: white;
      //margin-right: 0.7rem;
    }
  }
  text-indent: -5px;
}

//el texto cuando es escritorio
span.nav-label {
  padding-left: 12px;
}

nav.navbar-default.navbar-static-side {
  @include media(">=768px", "screen") {
    margin-top: 3.5rem;
  }
}

.none {
  display: none !important;
}

.opacity0 {
  opacity: 0;
}

.botones-home {
  height: 100%;
}

.hr {
  display: block;
  background-color: $colorBorder;
  height: 1px;
  width: 92%;
  margin: 1.5rem auto;

  @include media(">=768px", "screen") {
    display: none;
  }
}

//header modal
.modal-content .modal-header {
  flex-direction: row !important;
  align-items: center;
}

.inmodal .modal-header {
  padding: 15px;
  text-align: center;
  display: flex;
}
.inmodal .modal-title {
  font-size: 14px;
}

/* .form-control {
  background: #ffffff !important;
  border: 1px solid #cdd3d8 !important;
}
 */
ul.nav.navbar-top-links.navbar-right {
  margin-right: 1rem;
}

.contenido {
  margin-top: 20px;
  &.multimedia {
    .col-12:last-child {
      margin-bottom: 1rem;
    }
  }
}

.resumen-pago {
  display: flex;
  flex-flow: column nowrap;
  align-items: end;
  &_total {
    display: flex;
    margin-left: auto;
    p {
      margin: 0;
      font-size: 1.5rem;
      &.total {
        font-weight: bold;
        margin-left: 1rem;
      }
    }
  }
  &_boton {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
}

.btn-publicacion {
  display: flex;
  justify-content: center;
}

/* #page-wrapper {
  padding: 10px;
}
 */
.navbar.navbar-static-top {
  padding: 0 22px 0 15px;
}

@media (min-width: 768px) {
  .navbar-static-side {
    width: 205px;
  }
}

.text-suspender {
  color: $suspender;
}
.text-activa {
  color: $activa;
}

.text-pendiente {
  color: $blue;
}

.text-aprobar {
  color: $yellow;
}

.bgsuspender {
  background: $suspender !important;
  color: white !important;
  .fa-check {
    color: white;
  }
}

.label-desactiva {
  background-color: white !important;
  color: $suspender !important;
}

.btn-suspendida {
  background: var(--purple);
  border-color: var(--purple);
  color: white;
  &:hover {
    color: white;
    background: #663ab7;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
  }
}

.btn-pack {
  background: white;
  color: darken($pink, 20%);
  font-weight: bold;
  border: 1px solid darken($pink, 2%);
  i {
    color: darken($pink, 20%);
  }
  &:hover {
    color: white;
    background: darken($pink, 10%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    i {
      color: white;
    }
  }
}

.label-pack {
  color: white !important;
  background: darken($pink, 20%) !important;
  text-indent: 0;
}

/**
 *
 * INDEX
 *
 */

.seccion-bienvenida {
  position: relative;
  h2 {
    font-weight: bold;
    margin-top: 0 !important;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  /* span {
      font-weight: bold;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }*/
  #config {
    position: absolute;
    top: 0;
    right: 15px;
  }
}

// bloque que dice que vea el tutorial de como funciona el admin
.aprende {
  p {
    color: white;
    margin: 0;
    font-size: 17px;
    span {
      a {
        color: currentColor;
        &:hover {
          color: currentColor;
        }
      }
    }
  }
}

span {
  a {
    color: currentColor;
    &:hover {
      color: currentColor;
    }
  }
}

.info-suspension {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  &-dl {
    width: 125px;
  }
}

.badge-pe {
  background-color: #415663 !important;
  color: white !important;
  margin-left: 5px;
}

.info-devolucion {
  text-decoration: underline;
  margin-top: auto;
}

.info-dias-devueltos {
  text-align: center;
  max-width: 80%;
}

.toggle.btn-suspendida.off ~ .info-suspension {
  display: none;
}

//li para contar los dias suspendidos graficamente
.dias-suspendidos {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 115px;
  margin-top: 0.5rem;
  @include sinList;

  li {
    $sizeday: 1.5rem;
    background: #d0d0d0;
    display: block;
    border-radius: 2px;
    height: $sizeday;
    width: $sizeday;

    &:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .fa-check {
        font-size: 0.7rem;
        margin: 0 auto;
      }
    }
  }
}

.devolucion {
  padding-left: 0.8rem;
  li {
    color: #3e5476;
    a {
      color: currentColor;
      &:hover {
        color: lighten(#189c82, 5%);
      }
    }
  }
  li span a {
    font-weight: bold;
    color: #189c82;
    &:hover {
      color: lighten(#189c82, 5%);
    }
  }
}
.datos-publicados,
.config {
  dt,
  dd {
    border-bottom: 1px solid $colorBorder;
    height: 2rem;
    margin-bottom: 0;
    padding: 1.2rem 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  dt {
    font-weight: normal;
    float: left;
    margin-right: 0.3rem;
  }

  dd {
    font-weight: bold;
    white-space: nowrap;
    text-indent: 0.5rem;

    .btn-cambiar {
      margin-left: auto;
    }
  }

  &_ubicacion,
  &_tarifa-txt,
  &_promo-txt {
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

span.sale-block {
  background-color: red;
  color: white;
  font-weight: normal;
  padding: 0 4px;
  border-radius: 4px;
  margin-right: 4px;
}

.config {
  &-foto,
  &-shesay,
  &-disponible,
  &-story {
    margin-right: 0.2rem;
  }
}

.servicios-clientes {
  &_mes,
  &_semana,
  &_hoy {
    display: flex;
    padding: 0.5rem 0;
    border-bottom: 1px solid $colorBorder;
    width: 100%;
    li {
      margin-right: 0.5rem;
      span {
        font-weight: bold;
      }
      &:nth-child(2),
      &:nth-child(3) {
        font-weight: bold;
      }
    }
  }
}

.novhome {
  border-top: 1px solid $colorBorder;
  padding: 0.3rem;
  &-link {
    display: flex;
    align-items: center;
    &_right {
      margin-left: auto;
      i {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    &:hover {
      color: darken(coral, 5);
    }
  }
  &:last-child {
    border-bottom: 1px solid $colorBorder;
  }
}

.icon::before,
.icon::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

//modal servicio clientes
#clientesyservicios {
  p {
    color: $azultxt;
  }
  .frase {
    position: relative;
    padding: 1rem;
    &-text {
      text-align: center;
      margin: 0;
      span {
        color: $gray-700 !important;
        font-style: italic;
        font-weight: bold;
        position: relative;
        z-index: 1;
      }

      &::before,
      &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 2rem;
        color: #dedede !important;
        position: absolute;
        z-index: 0;
      }
      &::before {
        content: "\f10d";
        top: 0px;
        left: 10px;
      }
      &::after {
        content: "\f10e";
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .clientesyservicios-list {
    list-style: disc;
    padding-left: 1rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
}

//modales
.PublicacionExplicacion-body,
.SolicitudAgencia-body,
.DisponibleComoFunciona-body {
  h2 {
    font-size: 19px;
    font-weight: 700;
  }
  p {
    color: $azultxt !important;
  }
}

.publicaciones-explicacion,
.publicaciones-devolucion {
  list-style: disc;
  padding-left: 1rem;
  li {
    margin-bottom: 0.5rem;
  }
}

.ayuda-visual {
  p {
    margin-top: 1rem;
    color: $azultxt !important;
  }
}

//flo  NEGRA
.flo -negra_content {
  h5,
  h4,
  small {
    margin: 0 0 0.3rem 0;
  }
}

.iboxTitle {
  font-size: 19px;
  margin-bottom: 0;
}

.ibox-small {
  font-size: 0.9rem;
}

.grid .ibox {
  margin-bottom: 0;
}

/* .grid {
  @include media('>=667px','screen'){
    column-count: 2;
  }
  @include media('>=1024px','screen'){
    column-count: 3;
  }
  @include media('>=1280px','screen'){
    column-count: 4;
  }
} */

// .grid-item {
//   margin-bottom: 25px;
//   width: 300px;
// }

/* .grid-sizer,
.grid-item {
  width: 100%;
  margin-bottom: 15px;
}
 */

$colorTabsPortales: #627887;

//clase para las publicaciones
//cuando tiene publicación en PE y LP
.tabs-publi {
  justify-content: space-between;
  li {
    a {
      border: 1px solid $colorTabsPortales;
      color: $colorTabsPortales;
      &.active {
        background: $colorTabsPortales !important;
        color: darken($colorTabsPortales, 25%);
        .badge-portal {
          margin-left: 5px;
          background-color: #ffe549;
        }
      }
      .badge-portal {
        margin-left: 5px;
        background-color: #e2e2e2;
      }
    }
  }
}

.grid {
  max-width: 100%;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  &-item {
    width: auto;
    height: auto;
    float: left;
    margin: 0 10px 10px 0;

    @include media("<=500px", "<667px", "screen") {
      width: 100%;
      margin-right: 0;
    }

    @include media(">=667px", "<1024px", "screen") {
      width: calc(100% / 2 - 6px);
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }
    @include media(">=1024px", "<1280px", "screen") {
      width: calc(100% / 3 - 7px);
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
    @include media(">=1280px", "screen") {
      width: calc(100% / 4 - 8px);
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }
  }
}

.blacklist-content {
  position: relative;

  &_date {
    position: absolute;
    right: 20px;
  }
  &_title {
    padding-right: 72px;
    margin-top: 0;
  }
  &_phone {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }
  &_text {
    border-top: 1px solid $colorBorder;
    padding: 0.8rem 0;
    margin-bottom: 0.2rem;
  }
  &_category {
    span {
      font-weight: 600;
    }
  }
  &_ButtonCount {
    display: flex;
    justify-content: flex-end;
  }
  &_count {
    border: 1px solid $colorBorder;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    width: 18px;
    p {
      margin: 0;
    }
  }
}

.flo {
  height: 6rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.slick-dots {
  bottom: -25px;
}

//novedades
.novedades {
  &-content {
    &_item {
      max-height: 24rem;
      padding: 2rem 1.5rem;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

//portadas
.portadas-content {
  &_titulo {
    text-align: center;
  }

  &_imagen {
    &.top {
      img {
        width: 150px;
        height: auto;
        margin: 0 auto;
        border-radius: 4px;
      }
    }
  }
}

//para el modal tipos de portadas
.tipo {
  display: flex;
  flex-flow: column nowrap;
  &-portada {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 1rem;
    &_img {
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
    &_txt {
      padding-left: 1rem;
    }
  }
}

//AMIGAS

.amigas {
  &_content {
    &-solicitud {
      &_list {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        @include sinList;

        &-li {
          margin-right: 0.5rem;
          padding: 0.5rem;
          border: 1px solid $colorBorder;
          border-radius: 4px;

          display: flex;
          flex-flow: column nowrap;
          align-items: inherit;

          &_top {
            border-radius: 4px;
            width: 100px;
            height: 100px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          &_bottom {
            //margin-top: .5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            button {
              margin-bottom: 0.4rem;

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

//solicitud de agencia
.agencia-solicitud_pendiente {
  display: flex;
  flex-flow: column wrap;
  &-modelo {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.ico {
  font-size: 1.5rem;
}

//-----------------------------------///
// FORMULARIO PARA CAMBIAR DATOS PUBLICADOS  //
//-----------------------------------///
.listado-cambios {
  list-style-type: disc;
  padding-left: 16px;

  li {
    margin-bottom: 0.3rem;
  }
}

h5 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.form-check-inline {
  margin-bottom: 0.5rem;

  .form-check-label {
    margin-left: 0.5rem;
  }
}

.form-group {
  label {
    font-weight: bold;
  }
}

#descripcion-texto {
  min-height: 230px;
}
//-----------------------------------///
// GALERIA  //
//-----------------------------------///
//home index

.listado_galerias {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &-item {
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
    //telefono landscape chico iphone5
    @include media(">=phone", "<=568px", "screen", "landscape") {
      width: calc(100% / 3 - 6px);
      margin-right: 0.5rem;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    //telefono iphone 7
    @include media(">phone", "<767px", "screen", "portrait") {
      width: calc(100% / 2 - 4px);
      margin-right: 0.5rem;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    //telefono iphone 7 landscape
    @include media(">=667px", "<766px", "screen", "landscape") {
      width: calc(100% / 4 - 6px);
      margin-right: 0.5rem;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    //IPAD portrait
    @include media(">=tablet", "<desktop", "screen") {
      width: calc(100% / 4 - 6px);
      margin-right: 0.5rem;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    //IPAD landscape
    @include media(">=desktop", "<=1450px", "screen") {
      width: calc(100% / 4 - 6px);
      margin-right: 0.5rem;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    //IPAD landscape
    @include media(">1450px", "screen") {
      width: calc(100% / 7 - 7px);
      margin-right: 0.5rem;
      &:nth-child(7n) {
        margin-right: 0;
      }
    }

    &_top {
      position: relative;

      border: 5px solid #d1dade;
      border-radius: 10px;
      overflow: hidden;

      .icheckbox_square-green {
        position: absolute !important;
        top: 10px;
        right: 10px;
        background-color: white;
        width: 22px;
        height: 22px;
        z-index: 1;
        border-radius: 3px;
      }
      .bgiCheck {
        position: absolute !important;
        top: 10px;
        right: 10px;
        background-color: white;
        z-index: 0;
      }
      button {
        position: absolute;
        bottom: 10px;
        left: 10px;
      }
      a {
        display: block;

        img {
          width: 100%;
          //border-radius: 10px;
        }
      }
    }
    &_bottom {
      margin: 5px 0 0 0;
      padding: 0 7px;
      .lg-info {
        width: 100%;
        margin-bottom: 0;
        dt {
          float: left;
          margin-right: 5px;
        }
        dd {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Colores para el borde de la galería para indicar el estado
//Solo cambia el color del borde, por defecto esta no publicada
.publicada {
  border-color: #1ab394;
}

.pendiente {
  border-color: #f8ac59;
}

.no-publicada {
  border-color: #d1dade;
}

.rechazada {
  border-color: #ff4343;
}

.cut-portada {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.ejemplo-fotos {
  img {
    width: 100%;
  }
}
.caducar {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 1rem;
  background: aliceblue;
  padding: 0.5rem;
  font-weight: bold;
  width: 100%;
  display: none;
}

//alerta de fotos no publicadas automarticamente
.alerta {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  &-icon {
    font-size: 20px;
  }
  &-txt {
    font-size: 20px;
    font-weight: bold;
  }
  animation: parpadeo 1s infinite;
}

.titulo-verificacion {
  animation: parpadeo 1s infinite;
}

/* Define la animación de parpadeo */
@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.info-union {
  i {
    font-size: 2rem;
    margin-left: 0.5rem;
  }
}
.info-pago {
  i {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
}

.cta-cte {
  background-color: #fff;
  border: 1px solid gray;
  padding: 16px;
  border-radius: 10px;
  dl {
    dt,
    dd {
      margin-bottom: 0.5rem;
    }
    dt {
      margin-right: 0.5rem;
    }
  }
}

.ul-precios,
.list-banco,
.list-preguntas {
  list-style: disc;
  padding-left: 1rem;
  li {
    margin-top: 0.2rem;
    a:hover {
      color: $navy;
    }
  }
}

.metismenu {
  li {
    a {
      color: $text-color;
    }
  }
}

.fa-trophy {
  font-size: 5rem;
  color: $gold;
}

.txt-trophy p {
  font-size: 1.5rem;
  line-height: 1.2;
}

.paper {
  background: #fff;
  padding: 30px;
  position: relative;
}

.selectGal {
  background-color: #1ab394;
}

.instrucciones-text {
  list-style: decimal;
  padding-left: 15px;

  li {
    margin-bottom: 0.7rem;

    .instrucciones-text_portadas {
      list-style: square;
      padding: 0 0 0 20px;
      margin: 1rem 0 !important;

      li {
        margin-bottom: 0.7rem;
      }
    }

    img {
      margin: 0.5rem 0;
    }
  }
}

.info-galerias {
  padding-left: 14px;
  margin-top: 0.2rem;

  dt,
  dd {
    font-size: 11px;
    margin-bottom: 0;
  }

  dt {
    font-weight: 500;
    float: left;
    margin-right: 0.3rem;
  }

  dd {
    font-weight: bold;
  }
}

.info-dl {
  dt {
    font-weight: bold;
  }
  dd {
    font-weight: normal;
  }
}

.img-edicion {
  width: 100%;

  @include media("<=405px", "screen") {
    width: 242px;
  }
}

.delete {
  border: 2px dashed red;
}

.bloqueAgencia {
  width: 100%;
  padding: 1rem;
  background: #f3f3f4;
  border-radius: 14px;

  select {
    background: #fff;
  }

  &_modelo {
    display: flex;
    flex-direction: row;

    &-img {
      width: 25%;

      img {
        border-radius: 4px;
        width: 100%;
      }
    }

    &-list {
      margin-left: 0.5rem;
      width: 75%;

      &_name {
        margin: 0;
      }
    }
  }
}

.simbologia {
  margin-bottom: 1rem;

  li {
    margin-bottom: 0.5rem;

    span {
      margin-right: 0.4rem;
    }

    span:last-child {
      font-weight: normal;
    }
  }
}

.foto-delete {
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 1px dashed red;
}

.boton-subir {
  display: flex;
  align-items: center;
}

.galeria {
  .ibox-title {
    background: lighten(#cfced4, 20%) !important;

    .form-group {
      margin-bottom: 0 !important;
    }
  }
}

.herramientas {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

//herramientas para editar fotos, portadas, etc
.herramientas-editor {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  max-width: 500px;
  margin: 0 auto;
}

.ibox-heading {
  background-color: #cfced4 !important;
  padding: 10px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}

.ibox-galerias,
.ibox-portadas-opciones {
  padding: 10px;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.galerias,
.portadas-opciones-ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @include sinList;

  &_li {
    margin-bottom: 1.5rem;
    width: 48%;

    &-content {
      background-color: #f0f8ff;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;

      .icheckbox_square-green {
        position: absolute;
        z-index: 1;
        right: 3%;
        background-color: #f0f8ff;
        top: 3%;
      }

      .select-img {
        position: absolute;
        top: 2%;
        right: 2%;
        z-index: 1;
      }

      .li-img {
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
    @include media(">=375px", "<tablet", "landscape") {
      width: calc(100% / 4 - 13px);
    }
    @include media(">=tablet", "<desktop") {
      width: calc(100% / 5 - 11px);
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    @include media(">=desktop", "<1500px") {
      width: calc(100% / 6 - 12px);
      &:nth-child(6n) {
        margin-right: 0;
      }
    }
    @include media(">=1500px") {
      width: calc(100% / 10 - 12px);
      &:nth-child(10n) {
        margin-right: 0;
      }
    }
  }

  @include media(">=768px", "screen") {
    justify-content: flex-start;

    li {
      margin-right: 0.8rem;
    }
  }
}

.activar-foto {
  position: absolute;
  bottom: 2%;
  left: 2%;
  z-index: 1;
}
.contentFotoEdit {
  padding: 10px 25px;
  img {
    width: 100%;
  }
}

.inactiva {
  opacity: 0.45;
  filter: grayscale(100%);
}

/**
 *
 * destacadas
 *
 */

.destacada-online,
.destacada-offline {
  img {
    width: 100%;
    border-radius: 10px;
  }
}
/**
 *
 * tutoriales
 *
 */

.tutoriales {
  h3 {
    margin: 20px 0 5px 0 !important;
    &:first-of-type {
      margin: 0 !important;
    }
  }
  .listTuto {
    list-style: disc;
    padding: 0 0 0 15px;
    li {
      a {
        font-size: 1rem;
        &:hover {
          color: $greenT;
        }
      }
    }
  }
}

/**
 *
 * seccion portadas
 *
 */

#portadas-tab,
#ellasdicentabs,
#disponibles-tab,
#estadisticas-tab {
  flex-flow: row nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: space-between;
  li {
    margin-right: 0.5rem;
    a {
      background-color: $colorBorder;
      padding: 9px 14px;
      font-size: 12px;
      white-space: nowrap;
    }
    a.active {
      background-color: #1c84c6;
      color: white !important;
    }
  }
  border-bottom: 1px solid $colorBorder;
  padding-bottom: 1rem;
}

.portada-content {
  padding: 0 15px;

  .ibox {
    @include media(">=768px", "screen") {
      padding: 0 15px;
    }
  }
}

.portada {
  flex-direction: column;
  align-items: center;

  &-activa {
    width: 150px;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    .portada-img {
      border: 1px solid gray;
      width: 100%;
      border-radius: 4px;
    }
  }
}

#portada-destacadas div div div div {
  .portada-activa {
    width: 300px;
  }
}

.portadas-inactivas {
  &_list {
    display: flex;
    justify-content: space-between;
    @include sinList;

    &-li {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      max-width: 100px;
      margin-right: 0.5rem;

      &_img {
        display: block;
        margin-bottom: 0.5rem;

        img {
          border: 1px solid gray;
          width: 100%;
          border-radius: 4px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include media(">=768px", "screen") {
      justify-content: flex-start;

      li {
        margin-right: 0.8rem;
      }
    }
  }
}

.portadas-opciones-ul {
  &_li {
    display: flex;
    flex-direction: column;
  }
}

/**
 *
 * HISTORIAS
 *
 */
.videos-content_top {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.link-video {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid lightgray;
  padding: 0.5rem 0.2rem;
  &:last-child {
    border-bottom: 1px solid lightgray;
  }
  &_a {
    text-decoration: underline !important;
    text-overflow: ellipsis;
    width: 95px;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      color: $greenT;
    }
  }
}

.historias {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;

  &_li {
    display: block;
    margin-right: 0.5rem;
    padding: 0.5rem;
    border: 1px solid $colorBorder;
    border-radius: 4px;
    width: 117px;

    &-top {
      video {
        background-color: $colorBorder;
        height: 190px;
        width: 100px;
      }
    }

    &-bottom {
      .edicion-video {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
    }
  }

  &.aceptadas {
    @include media(">=768px", "screen") {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}

//HISTORIAS
.videos {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
  list-style: none;

  &_li {
    display: block;
    margin-right: 0.5rem;
    padding: 0.5rem;
    border: 1px solid $colorBorder;
    border-radius: 4px;
    width: auto;

    &-top {
      video {
        background-color: $colorBorder;
        height: 200px;
        width: 200px;
      }
    }

    &-bottom {
      .edicion-video {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: space-between;
      }
    }
  }
  &.aceptadas {
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    list-style: none;
    display: flex;
  }

  /*   &.aceptadas {
    @include media('>=768px', 'screen') {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  } */
}

.videos_aceptados-content {
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 1rem;
  }
}

.videos-top {
  video {
    background-color: $colorBorder;
    height: auto;
    width: 100%;
  }
}
.videos-bottom {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

%flex {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

// agencias

.recuerda {
  list-style: disc;
  padding: 0 0 0 15px;
}

.sinInfo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 8rem;
}

.agencia-publicaciones {
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 0 !important;

  &_item {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border-bottom: 1px solid $colorBorder;
    padding: 1rem 0;

    &-nombre {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    &-info {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

ul.por-verificar {
  padding-left: 20px;
  list-style: decimal;
  li {
    text-align: left;
    font-weight: bold;
    color: yellow;
  }
}

.nombre-modelo {
  display: flex;
  flex-flow: row nowrap;
}

.transferir-publicacion {
  color: $lazur;
  margin-right: 5px;
  padding: 5px 0 0 0;
}

.escort-exclusiva {
  color: gold;
  margin-right: 3px;
  padding-top: 5px;
  display: none;
}

.nombre-escort {
  font-size: 1.2rem;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @extend %flex;
}

div[class^="publicacion-"] {
  display: flex;
}

.info-publicacion-ind {
  flex-grow: 3;
}

.escort-exclusiva {
  i {
    color: gold;
    font-size: 1rem;
  }
}

dl {
  margin: 5px 0 0 0;
  width: 100%;
  dt {
    float: left;
    margin-right: 3px;
  }
  dd {
    margin-bottom: 0;
  }
}

.info-bottom {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.preguntas {
  dt {
    float: none;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }
  dd {
    font-weight: normal;
    margin-bottom: 1rem;
  }
}
.dias-suspendidosAgencia {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: auto;
  margin-top: 0.2rem;
  @include sinList;

  li {
    $sizeday: 1rem;
    background: #d0d0d0;
    display: block;
    border-radius: 2px;
    height: $sizeday;
    width: $sizeday;
    margin-right: 0.2rem;

    &:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .fa-check {
        font-size: 0.7rem;
        margin: 0 auto;
      }
    }
  }
}

.info-suspensionAgencia {
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  width: 100%;
}

.desactiva {
  filter: grayscale(100%);
}

.alerta-pantalla {
  display: none;

  @include media("<=320px", "screen") {
    display: block;
    text-align: center;

    h5 {
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }
}

.alert-danger {
  a {
    color: #721c39;
    text-decoration: underline;
    &:hover {
      color: #721c39;
    }
  }
}

//padding para el cambio de correo o contraseña
#mailpass {
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 20px 10px !important;
      }
    }
  }
}

//transferencia de publicacion
.transPub {
  display: flex;
  flex-flow: column nowrap;
  &-top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    &_icon {
      align-items: center;
      display: flex;
      i {
        font-size: 3rem;
      }
    }
  }
  &-bottom {
    margin-top: 2rem;
  }
}
/**
 *
 * P O P U L A R I D A D
 *
 */
//ranking portadas de perfil
.ranking {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &-portada,
  &-text {
    width: calc(100% / 2);
  }
  &-portada {
    .estadisticas-perfil {
      max-width: 110px;
    }
  }

  &-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  //las opciones de portada de perfil
  &_opciones {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-portada,
    &-text {
      width: calc(100% / 2);
    }
    &-text {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    @include media(">=576px", "<1023px", "screen") {
      flex-direction: column;
      &-portada,
      &-text {
        width: 100%;
        display: flex;
      }
    }
  }

  &_destacada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-portada {
      img {
        width: 100%;
        @include media(">767px", "screen") {
          max-width: 200px;
        }
      }
    }
    &-text {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    @include media(">=576px", "screen") {
      flex-direction: row;
      &-portada,
      &-text {
        width: calc(100% / 2);
        display: flex;
      }
    }
  }
}

.overflox-x {
  overflow-x: scroll;
  overflow-y: hidden;
}
.tabla-contacto {
  @include media("<=375px", "screen") {
    width: 135%;
  }
}

.text-posicion {
  font-weight: bold;
  margin-top: 1rem;
  font-size: 15px;
  text-align: center;
}

.trofeo {
  display: flex;
  justify-content: center;
  i {
    font-size: 2.2rem;
    line-height: 3rem;
    margin-right: 1rem;
  }
  p {
    font-size: 3rem;
    line-height: 3rem;
  }
}

/**
 *
 * Ellas dicen
 *
 */

#ellasdicentabs {
  border-bottom: 1px solid $colorBorder;
  padding-bottom: 1rem;
}

/**
  *
  * paquetes
  *
  */
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.pricing {
  background-color: white;
  box-shadow:
    0 1px 8px rgba(0, 0, 0, 0.05),
    0 1px 2px rgba(0, 0, 0, 0.1);
  margin: 6px;
  border-radius: 4px;
}

.pricing-plan {
  border-radius: 4px;
}
.pricing-plan li {
  padding: 10px 16px;
  border-top: 1px solid #e7eaec;
  text-align: center;
  color: $azultxt;
  font-size: 0.85rem;
  span {
    font-weight: 600;
  }
}

li.pricing-title {
  background: $greenT;
  color: #fff;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  font-size: 1.8rem;
  font-weight: 600;
}

li.pricing-desc {
  font-size: 13px;
  border-top: none;
  padding: 20px 16px;
}

.precio {
  font-size: 1.8rem;
}

.listado-tutoriales {
  &_titulo {
    font-weight: 600;
  }
  li {
    margin-bottom: 0.6rem;
    ul {
      list-style: inside;
      li a {
        color: $azultxt !important;
      }
    }
  }
}

//lista negra
.listanegra-img {
  img {
    width: 100%;
  }
}

// depositos
.deletepubli i,
.delete-examen i {
  color: white;
}

//examenes
.examen {
  display: flex;
  flex-flow: row wrap;

  &-item {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 7px;
    display: flex;
    margin: 0 7px 7px 0;

    &_btn {
      margin-right: 0.7rem;
    }

    &_img {
      &_click {
        color: #0056b3;
        text-decoration: underline;
        &:hover {
          color: coral;
          text-decoration: underline;
        }
      }
    }
  }
}

.examen-modal {
  img {
    width: 100%;
  }
}

//comentarios de clientes
.info-comentario {
  color: $azultxt;
  &_user {
    font-weight: bold;
  }
  &_estado {
    text-transform: uppercase;
  }
}

.preciosacc {
  color: $azultxt !important;
}

//formulario de registro
//servicios

.span-content {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1rem;
  span:first-child {
    margin-right: 0.5rem;
  }
  .nombre-servicio {
    margin-left: 0.5rem;
  }
}

.opcion-servicios {
  display: flex;
  flex-direction: row;
  span {
    font-weight: bold;
    width: 25px;
    height: 22px;
    text-align: center;
    &.incluidos {
      color: $navy;
    }
  }
}


.fotos-up {
  margin-top: 1rem;
  &_list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    &-li {
      width: 48%;
      max-width: 150px;
      &_foto {
        img {
          width: 100%;
        }
      }
      &_tool {
        margin-bottom: 1rem;
      }
    }
  }
}

.registro-nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-between;
}

$colors: (
  "pe": #ff4343,
  "lp": #2ca9c9,
  "ce": #575f61,
  "cn": #a58c1b,
  "ss": #1b792f
);

@each $name, $color in $colors {
  .lebel-#{$name} {
    color: white;
    background-color: $color;
    border: 0px solid $color;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    width: 27px;
    height: 27px;
    font-size: 11px;
    line-height: 1.5;
    font-weight: bold;
  }
}
